export const secondToHours = (sec) => {
  if (!sec) return "00h 00m";
  sec = Number(sec);
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec % 3600) / 60);

  return `${hours}h ${minutes}m`;
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return "N/A";
  const lowerCaseStringOrPhrase = string.toLowerCase().split(" ");

  const capitalizedWords = lowerCaseStringOrPhrase.map(
    (word) => word[0].toUpperCase() + word.substr(1),
  );

  return capitalizedWords.join(" ");
};

export const getTask = (object) => {

  const newObject = {
    ...object,
    // name: object.name,
    // description: object.description,
    // priority: object.priority ? object.priority.id : null,
    // due_date: object.due_date,
    // due_date_time: true,
    // start_date: object.start_date,
    // start_date_time: true,
    // status_id:
    //   object.status.id !== object.status_id
    //     ? object.status.id
    //     : object.status_id,
    // notify_all: true,
    // check_required_custom_fields: true,
    // observers_id: object.observers.map((elem) => elem.id),
    priority_id: object.priority ? object.priority.id : null,
    // sector_id: object.sector ? Array.from(object.sector.id) : [],
    // service_id: object.service ? Array.from(object.service.id) : [],
    // inventory_id: object.inventory ? Array.from(object.inventory.id) : [],
    // check_in: object.check_in,
    // check_out: object.check_out,
    // worked_time: object.worked_time,
  };

  return newObject;
};
