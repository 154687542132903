import React, { useEffect, useState } from "react";
import moment from "moment";
import { Formik } from "formik";
import "./Dashboard.css";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { LoadingOutlined } from "@ant-design/icons";

// import Select from "../../components/Select/Select";
// import { Formik } from "formik";
// import Input from "../../components/Input/Input";
import { useSelector } from "react-redux";
import { userSelector } from "../../redux/Selectors";
import Select from "~/components/Select/Select";
import InputDatepicker from "~/components/InputDatepicker/InputDatepicker";
import Button from "~/components/Button/Button";
import { SectorRoutes, TaskRoutes, DashboardUrlRoutes } from "~/http/routes";
import { Link } from "react-router-dom";
import { projectsIcon, reportIconWhite } from "~/assets/svg/Icons";
import MTTRCard from "./components/MTTRCard";
import MTBFCard from "./components/MTBFCard";
import METCard from "./components/METCard";
import MTTSCard from "./components/MTTSCard";
import TasksPieChart from "~/components/TasksPieChart/TasksPieChart";
import TasksStatusColumnChart from "~/components/TasksStatusColumnChart/TasksStatusColumnChart";

function Dashboard() {
  const userStore = useSelector(userSelector);
  const [dataColumn, setDataColumn] = useState(false);

  const [tasks, setTasks] = useState([]);
  const [calcLoading, setCalcLoading] = useState(true);
  const [totalPieChartData, setTotalPieChartData] = useState(false);
  const [totalTasks, setTotalTasks] = useState(0);
  const [overduePieChartData, setOverduePieChartData] = useState(false);
  const [totalOverdueTasks, setTotalOverdueTasks] = useState(0);
  const [unexpiredPieChartData, setUnexpiredPieChartData] = useState(false);
  const [totalUnexpiredTasks, setTotalUnexpiredTasks] = useState(0);
  const [futureDeliveryChartData, setFutureDeliveryChartData] = useState(false);
  const [totalFutureDeliveryTasks, setTotalFutureDeliveryTasks] = useState(0);
  const [donePieChartData, setDonePieChartData] = useState(false);
  const [totalDoneTasks, setTotalDoneTasks] = useState(0);

  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [sector, setSector] = useState("");
  const [project, setProject] = useState("");
  const [subProject, setSubProject] = useState("");
  const [sectors, setSectors] = useState([]);
  const [projects, setProjects] = useState([]);
  const [subProjects, setSubProjects] = useState([]);
  const [isPieChartsLoading, setPieChartsLoading] = useState(true);
  const [dashboardUrl, setDashboardUrl] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        await getDashboardUrl();
        await setPieChartsData();
        await setColumnChartData();
        await getTasks();
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchSectors = async () => {
      try {
        const { id, companyId } = userStore;
        const sectorsList = [];

        const response = await SectorRoutes.getNamesByUser(id, companyId);
        const sectors = response.data.data;

        for (const sector of sectors) {
          sectorsList.push({
            value: sector.id,
            name: sector.name,
          });
        }

        setSectors(sectorsList);
      } catch (err) {
        console.log(err);
      }
    };

    fetchSectors();
  }, [userStore]);

  useEffect(() => {
    const fetchProjects = async () => {
      const { projectInfos } = userStore;
      const projects = [];

      for (const project of projectInfos) {
        projects.push({
          value: project.id,
          name: project.name,
          subProjects: project.subProjects,
        });
      }

      setProjects(projects);
    };

    fetchProjects();
  }, [userStore]);

  async function getTasks() {
    setCalcLoading(true);

    try {
      const params = {
        initial_creation_date: initialDate || "",
        final_creation_date: finalDate || "",
        projects: [project],
        subprojects: [subProject],
        sectors: [sector],
      };

      const res = await TaskRoutes.getReport(userStore.companyId, params);
      const tasks = res.data.data;

      setTasks(tasks);
    } catch (err) {
      console.log("err:", err);
    }

    setCalcLoading(false);
  }

  async function getTotalTasksIndicators(priority, status, expiration) {
    try {
      const { companyId } = userStore;

      const where = {
        ...(status && { status }),
        ...(priority && { priority }),
        ...(sector && { sector }),
        ...(project && { project }),
        ...(subProject && { subProject: subProject }),
        ...(expiration && { expiration }),
        ...(initialDate && { initialDate }),
        ...(finalDate && { finalDate }),
      };

      return await TaskRoutes.getIndicators(companyId.toString(), where);
    } catch (err) {
      return err;
    }
  }

  async function getTotalTasksByPriorities(status, expiration) {
    try {
      let noPriority = await getTotalTasksIndicators("0", status, expiration);
      let low = await getTotalTasksIndicators("1", status, expiration);
      let moderate = await getTotalTasksIndicators("2", status, expiration);
      let urgent = await getTotalTasksIndicators("3", status, expiration);
      let total = 0;

      noPriority = noPriority.data.data.total || 0;
      low = low.data.data.total || 0;
      moderate = moderate.data.data.total || 0;
      urgent = urgent.data.data.total || 0;

      total += noPriority + low + moderate + urgent;

      return {
        low,
        moderate,
        urgent,
        noPriority,
        total,
      };
    } catch (err) {
      return err;
    }
  }

  function getPrioritiesDataToChart(indicators) {
    const data = [];

    if (indicators.low > 0) {
      data.push({ type: "BAIXA", value: indicators.low });
    }

    if (indicators.moderate > 0) {
      data.push({ type: "MODERADO", value: indicators.moderate });
    }

    if (indicators.urgent > 0) {
      data.push({ type: "URGENTE", value: indicators.urgent });
    }

    if (indicators.noPriority > 0) {
      data.push({ type: "SEM PRIORIDADE", value: indicators.noPriority });
    }

    return data;
  }

  async function getTotalTasksPieChart() {
    try {
      const indicators = await getTotalTasksByPriorities();

      return {
        data: indicators,
        total: indicators.total,
      };
    } catch (err) {
      return err;
    }
  }

  async function getOverdueTasksPieChart() {
    try {
      const indicators = await getTotalTasksByPriorities(false, "overdue");

      return {
        data: indicators,
        total: indicators.total,
      };
    } catch (err) {
      return err;
    }
  }

  async function getUnexpiredTasksPieChart() {
    try {
      const indicators = await getTotalTasksByPriorities(false, "unexpired");

      return {
        data: indicators,
        total: indicators.total,
      };
    } catch (err) {
      return err;
    }
  }

  async function getFutureDeliveryTasksPieChart() {
    try {
      const indicators = await getTotalTasksByPriorities(
        false,
        "futureDelivery",
      );

      return {
        data: indicators,
        total: indicators.total,
      };
    } catch (err) {
      return err;
    }
  }

  async function getDoneTasksPieChart() {
    try {
      const indicators = await getTotalTasksByPriorities("CONCLUÍDO");

      return {
        data: indicators,
        total: indicators.total,
      };
    } catch (err) {
      return err;
    }
  }

  async function setPieChartsData() {
    try {
      setPieChartsLoading(true);

      const totalTasks = await getTotalTasksPieChart();
      const overdueTasks = await getOverdueTasksPieChart();
      const unexpiredTasks = await getUnexpiredTasksPieChart();
      const futureDeliveryTasks = await getFutureDeliveryTasksPieChart();
      const doneTasks = await getDoneTasksPieChart();

      setTotalPieChartData(getPrioritiesDataToChart(totalTasks.data));
      setTotalTasks(totalTasks.total);

      setOverduePieChartData(getPrioritiesDataToChart(overdueTasks.data));
      setTotalOverdueTasks(overdueTasks.total);

      setUnexpiredPieChartData(getPrioritiesDataToChart(unexpiredTasks.data));
      setTotalUnexpiredTasks(unexpiredTasks.total);

      setFutureDeliveryChartData(
        getPrioritiesDataToChart(futureDeliveryTasks.data),
      );
      setTotalFutureDeliveryTasks(futureDeliveryTasks.total);

      setDonePieChartData(getPrioritiesDataToChart(doneTasks.data));
      setTotalDoneTasks(doneTasks.total);

      setPieChartsLoading(false);
    } catch (err) {
      return err;
    }
  }

  async function setColumnChartData() {
    try {
      const dataColumn = [];

      const todo = await getTotalTasksByPriorities("A FAZER");
      dataColumn.push({ status: "A FAZER", value: todo.total });
      const doing = await getTotalTasksByPriorities("FAZENDO");
      dataColumn.push({ status: "FAZENDO", value: doing.total });
      const waitingForApproval = await getTotalTasksByPriorities(
        "AGUARDANDO APROVAÇÃO",
      );
      dataColumn.push({
        status: "AGUARDANDO APROVAÇÃO",
        value: waitingForApproval.total,
      });
      const done = await getTotalTasksByPriorities("CONCLUÍDO");
      dataColumn.push({ status: "CONCLUÍDO", value: done.total });

      setDataColumn(dataColumn);
    } catch (err) {
      return err;
    }
  }

  function handleInitialDateChange(e) {
    if (e) {
      setInitialDate(e);
    } else {
      setInitialDate("");
    }
  }

  function handleFinalDateChange(e) {
    if (e) {
      setFinalDate(e);
    } else {
      setFinalDate("");
    }
  }

  function handleSectorSelection(e) {
    const { value } = e.target;

    setSector(value);
  }

  function handleProjectSelection(e) {
    const { value } = e.target;

    setProject(value);
    setSubProject("");
    const project = projects.find((e) => e.value == value);
    if (project) {
      if (project.subProjects) {
        const subProjects = [];

        for (const subProject of project.subProjects) {
          subProjects.push({
            value: subProject.id,
            name: subProject.name,
          });
        }

        setSubProjects(subProjects);
      } else {
        setSubProjects("");
      }
    }
  }

  function handleSubProjectSelection(e) {
    const { value } = e.target;

    setSubProject(value);
  }

  async function handleSearchButtonClick() {
    try {
      await setPieChartsData();
      await setColumnChartData();
      await getTasks();
    } catch (err) {
      console.log(err);
    }
  }

  async function getDashboardUrl() {
    try {
      const { subprojectId, companyId } = userStore;

      const url = await DashboardUrlRoutes.getUrlBySubproject(
        companyId,
        subprojectId,
      );
      if (url.data.data.length > 0) {
        setDashboardUrl(url.data.data[0].url_pbi);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="w-full">
      <Breadcrumb title="Dashboard" routeToBack="/home" />

      <div className="mt-3 mb-4 d-flex">
        <Link to="/home/projects/myprojects" style={{ textDecoration: "none" }}>
          <button className="btn-dashboard">
            <i className="project-icon">{projectsIcon}</i>
            <span className="btn-title">Todos os projetos</span>
          </button>
        </Link>
        <Link
          to="/home/reports/detailed-report"
          style={{ textDecoration: "none" }}
        >
          <button className="btn-dashboard">
            <i className="report-icon">{reportIconWhite}</i>
            <span className="btn-title">Relatório Detalhado</span>
          </button>
        </Link>
      </div>
      {!dashboardUrl.includes("https://app.powerbi.com/view?") ? (
        <div className="mt-3 mb-4">
          <div className="card shadow-sm no-border mb-4">
            <div className="card-body">
              <Formik
                initialValues={{
                  initialDate,
                  finalDate,
                }}
              >
                <div className="row">
                  <div className="col-sm-6 col-md-3 col-lg-2">
                    <Select
                      placeholder="Setor"
                      value={sector}
                      handleChange={handleSectorSelection}
                      inputBackground="transparent"
                      labelBackground="#fafafa"
                      options={sectors}
                      withNull="TODOS"
                    />
                  </div>
                  <div className="col-sm-6 col-md-3 col-lg-2">
                    <Select
                      placeholder="Projeto"
                      value={project}
                      handleChange={handleProjectSelection}
                      inputBackground="transparent"
                      labelBackground="#fafafa"
                      options={projects}
                      withNull="TODOS"
                    />
                  </div>
                  <div className="col-sm-6 col-md-3 col-lg-2">
                    <Select
                      placeholder="Subprojeto"
                      value={subProject}
                      handleChange={handleSubProjectSelection}
                      inputBackground="transparent"
                      labelBackground="#fafafa"
                      options={subProjects}
                      disabled={!project}
                      withNull="TODOS"
                    />
                  </div>
                  <div className="col-sm-6 col-md-3 col-lg-2">
                    <InputDatepicker
                      name="initialDate"
                      label="Início"
                      placeholder="Selecionar Data"
                      value={initialDate}
                      onChange={handleInitialDateChange}
                    />
                  </div>
                  <div className="col-sm-6 col-md-3 col-lg-2">
                    <InputDatepicker
                      name="finalDate"
                      label="Fim"
                      placeholder="Selecionar Data"
                      value={finalDate}
                      onChange={handleFinalDateChange}
                    />
                  </div>
                  <div className="col-sm-12 col-md-2">
                    <Button size="large" onClick={handleSearchButtonClick}>
                      Buscar
                    </Button>
                  </div>
                </div>
              </Formik>
            </div>
          </div>

          <div className="card shadow-sm no-border">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-3 col-md-2 col-lg-1">
                  <p
                    className="priority_tag"
                    style={{ backgroundColor: "#EB5757" }}
                  >
                    Urgente
                  </p>
                </div>

                <div className="col-sm-3 col-md-2 col-lg-1">
                  <p
                    className="priority_tag"
                    style={{ backgroundColor: "#F2994A" }}
                  >
                    Moderado
                  </p>
                </div>

                <div className="col-sm-3 col-md-2 col-lg-1">
                  <p
                    className="priority_tag"
                    style={{ backgroundColor: "#2D9CDB" }}
                  >
                    Baixa
                  </p>
                </div>

                <div className="col-sm-3 col-md-2 col-lg-1">
                  <p
                    className="priority_tag"
                    style={{ backgroundColor: "#CFD8DC" }}
                  >
                    Sem prioridade
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ height: "230px" }}
            className="d-flex justify-content-around w-full"
          >
            <TasksPieChart
              title="TOTAL DE TAREFAS"
              data={totalPieChartData}
              titleFormater={totalTasks}
              loading={isPieChartsLoading}
            />

            <TasksPieChart
              title="ATRASADAS"
              data={overduePieChartData}
              titleFormater={totalOverdueTasks}
              loading={isPieChartsLoading}
            />

            <TasksPieChart
              title="EM DIA"
              data={unexpiredPieChartData}
              titleFormater={totalUnexpiredTasks}
              loading={isPieChartsLoading}
            />

            <TasksPieChart
              title="ENTREGA FUTURA"
              data={futureDeliveryChartData}
              titleFormater={totalFutureDeliveryTasks}
              loading={isPieChartsLoading}
            />

            <TasksPieChart
              title="CONCLUÍDAS"
              data={donePieChartData}
              titleFormater={totalDoneTasks}
              loading={isPieChartsLoading}
            />
          </div>

          <div className="card shadow-sm no-border">
            <div className="card-body">
              <TasksStatusColumnChart data={dataColumn} />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3 mt-4">
              <MTTRCard tasks={tasks} loading={calcLoading} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 mt-4">
              <MTBFCard tasks={tasks} loading={calcLoading} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 mt-4">
              <METCard tasks={tasks} loading={calcLoading} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 mt-4">
              <MTTSCard tasks={tasks} loading={calcLoading} />
            </div>
          </div>
        </div>
      ) : (
        <div className="Embed-container">
          <iframe
            title="report"
            width="100%"
            height="90%"
            src={dashboardUrl}
            sandbox="allow-scripts allow-same-origin"
            referrerPolicy="no-referrer-when-downgrade"
            loading="lazy"
            frameBorder="0"
            allowFullScreen={true}
          />
        </div>
      )}
    </div>
  );
}

export default Dashboard;
