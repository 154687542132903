import React, { useContext, useEffect, useRef, useState } from "react";
import { BeatLoader } from "react-spinners";
import { Formik } from "formik";
import "./CreateTask.css";
import Select from "../../components/Select/Select";
import AxiosInstance from "../../http/Axios";
import { useSelector } from "react-redux";
import { userSelector } from "../../redux/Selectors";
import { Form } from "formik-antd";
import InputDatepicker from "../../components/InputDatepicker/InputDatepicker";
import { UploadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import FormikInput from "~/components/Input/FormikInput";
import { TaskRoutes } from "~/http/routes/";
import { ToastContext } from "~/providers/ToastProvider";
import FormikSwitchCheckbox from "~/components/SwitchCheckbox/FormikSwitchCheckbox";
import { Upload, Button } from "antd";
import { formRoutes } from "~/http/routes";
import { FormOutlined } from "@ant-design/icons";
import FormFieldsModal from "./components/FormFieldsModal";
import FormikAntdSelect from "./FormikAntdSelect";
import AutomaticNotificationSelect from "~/components/AutomaticNotificationSelect";
import checkPermission from "~/helpers/checkPermission";
import CheckPermission from "~/components/CheckPermission";

const initialTask = {
  name: "",
  signature_required: false,
  checkin_required: false,
  usesRecurrence: false,
  recurrence: "",
  repetitions: "",
  description: "",
  permission: "",
  inventory_id: [],
  sector_id: [],
  service_id: [],
  members_id: [],
  due_date: "",
  due_date_time: true,
  start_date: "",
  start_date_time: true,
  priority: "",
  check_required_custom_fields: true,
  notify_all: true,
  check_in: "",
  check_out: "",
  worked_time: 0,
  fields: [],
  responsible_id: "",
  status_id: "",
  priority_id: "",
  observers_id: [],
  form_id: "",
  beginAutomaticNotification: "",
  deliveryAutomaticNotification: "",
};

function ModalCreateTask({
  handleClose,
  refreshList,
  taskEditingData,
  titleDisabled,
}) {
  const formikRef = useRef();
  const userStore = useSelector(userSelector);
  const [services, setServices] = useState([]);
  const [inventory, setInventory] = useState([]);
  // const [fields, setFields] = useState([]);
  const [sector, setSector] = useState([]);
  const [users, setUsers] = useState([]);
  const [subprojectStatus, setSubprojectStatus] = useState([]);
  const [forms, setForms] = useState([]);
  const [automaticNotificationOptions, setAutomaticNotificationOptions] =
    useState([
      { daysBefore: 0, notifyOnDue: true },
      { daysBefore: 1, notifyOnDue: true },
      { daysBefore: 2, notifyOnDue: true },
      { daysBefore: 3, notifyOnDue: true },
      { daysBefore: 7, notifyOnDue: true },
      { daysBefore: 15, notifyOnDue: true },
      { daysBefore: 30, notifyOnDue: true },
      { daysBefore: 45, notifyOnDue: true },
      { daysBefore: 60, notifyOnDue: true },
      { daysBefore: 1, notifyOnDue: false },
      { daysBefore: 2, notifyOnDue: false },
      { daysBefore: 3, notifyOnDue: false },
      { daysBefore: 7, notifyOnDue: false },
      { daysBefore: 15, notifyOnDue: false },
      { daysBefore: 30, notifyOnDue: false },
      { daysBefore: 45, notifyOnDue: false },
      { daysBefore: 60, notifyOnDue: false },
    ]);
  const [formFieldsModalIsVisible, setFormFieldsModalIsVisible] =
    useState(false);
  const { setToast } = useContext(ToastContext);
  const [priorities, setPriorities] = useState();
  const [arrayFiles, setArrayFiles] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [selectedFieldsFromModal, setSelectedFieldsFromModal] = useState();

  const recurrences = [
    { name: "Diário", value: "daily" },
    { name: "Semanal", value: "weekly" },
    { name: "Quinzenal", value: "fortnightly" },
    { name: "Mensal", value: "monthly" },
    { name: "Bimestral", value: "bimonthly" },
    { name: "Trimestral", value: "quarterly" },
    { name: "Semestral", value: "semester" },
    { name: "Anual", value: "yearly" },
  ];

  useEffect(() => {
    setSelectedFieldsFromModal(undefined);
    if (taskEditingData?.attachments.length > 0) {
      setArrayFiles(
        taskEditingData.attachments.map((image) => ({
          ...image,
          thumbUrl: image.thumbnail_small,
          name: image.title,
          url: image.url,
        })),
      );
    }
    if (!taskEditingData || taskEditingData?.attachments.length == 0) {
      setArrayFiles([]);
    }
  }, [taskEditingData, setArrayFiles]);

  const onFinish = () => {
    const status = taskEditingData ? "alterada" : "cadastrada";

    setToast({
      message: `Tarefa ${status} com sucesso!`,
      show: true,
      title: "Sucesso!",
      type: "success",
      autohide: true,
    });

    formikRef.current.handleReset(initialTask);
    refreshList();
    handleClose();
  };

  const submitFiles = (id, setSubmitting) => {
    arrayFiles.forEach(async (elem, index) => {
      try {
        if (!elem.parent_id) {
          const bodyFormData = new FormData();
          bodyFormData.append("attachment", elem.originFileObj);

          await AxiosInstance.post(
            userStore.subprojectId + "/task/" + id + "/attachment",
            bodyFormData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            },
          );
        }
      } catch (err) {
        setToast({
          message:
            "Sua tarefa foi cadastrada com sucesso, porém ocorreu um erro no cadastro dos seus anexos!",
          show: true,
          type: "warning",
          title: "Atenção!",
          autohide: false,
        });

        let changedArrayOfFiles = [...arrayFiles];

        changedArrayOfFiles[index] = {
          ...elem,
          status: "error",
        };

        setArrayFiles(changedArrayOfFiles);
        console.warn(err);
      }
    });

    filesToDelete.forEach((fileId) => {
      if (fileId) {
        AxiosInstance.delete(
          `${userStore.subprojectId}/task/${taskEditingData.id}/attachment/${fileId}`,
        );
      }
    });

    setFilesToDelete([]);
    onFinish();
    setArrayFiles([]);
    setSubmitting(false);
  };

  const postTask = (objectValues, setSubmitting, resetForm) => {
    AxiosInstance.post(userStore.subprojectId + "/task", objectValues)
      .then((res) => {
        if (arrayFiles) {
          submitFiles(res.data.data.id, setSubmitting, resetForm);
        } else {
          onFinish();
        }
      })
      .catch((err) => {
        setToast({
          message: err.message,
          show: true,
          type: "error",
          title: "Erro ao salvar sua tarefa!",
        });
        setSubmitting(false);
      });
  };

  const putTask = (objectValues, setSubmitting, resetForm, taskId) => {
    AxiosInstance.put(userStore.subprojectId + "/task/" + taskId, objectValues)
      .then((res) => {
        if (arrayFiles) {
          submitFiles(res.data.data.id, setSubmitting, resetForm);
        } else {
          onFinish();
        }
      })
      .catch((err) => {
        setToast({
          message: err.message,
          show: true,
          type: "error",
          title: "Erro ao alterar sua tarefa!",
        });
        setSubmitting(false);
      });
  };

  const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
    let dueDate;
    let startDate;

    setSubmitting(false);

    try {
      startDate = new Date(
        values.start_date.split("/").reverse().join("-"),
      ).getTime();
    } catch {
      startDate = taskEditingData.start_date;
    }

    try {
      dueDate =
        values.due_date === null
          ? null
          : new Date(values.due_date.split("/").reverse().join("-")).getTime();
    } catch {
      dueDate = taskEditingData.due_date;
    }

    // const fieldsSelected = fields.filter(
    //   (elem) => parseInt(elem.value) === parseInt(values.fields),
    // );
    if (!values.usesRecurrence) {
      values.recorrence_end = false;
    } else if (values.recurrence && !values.repetitions) {
      values.recorrence_end = true;
      values.recorrence_end_type = values.recurrence;
    } else if (values.recurrence && values.repetitions) {
      values.recorrence = {
        type: values.recurrence,
        repetition_count: Number(values.repetitions),
      };
    }

    const parsedBeginAutomaticNotification = values.beginAutomaticNotification
      ? JSON.parse(values.beginAutomaticNotification)
      : null;
    const parsedDeliveryAutomaticNotification =
      values.deliveryAutomaticNotification
        ? JSON.parse(values.deliveryAutomaticNotification)
        : null;

    //delete values.usesRecurrence;
    delete values.recurrence;
    delete values.repetitions;
    delete values.beginAutomaticNotification;
    delete values.deliveryAutomaticNotification;

    const objectValues = {
      ...values,
      due_date: dueDate,
      start_date: startDate,
      inventory_id: values.inventory_id ? [parseInt(values.inventory_id)] : [],
      sector_id: values.sector_id ? [parseInt(values.sector_id)] : [],
      service_id: values.service_id ? [parseInt(values.service_id)] : [],
      observers_id: values.observers_id || [],
      notify_before_start: parsedBeginAutomaticNotification
        ? parsedBeginAutomaticNotification.daysBefore
        : null,
      notify_before_due: parsedDeliveryAutomaticNotification
        ? parsedDeliveryAutomaticNotification.daysBefore
        : null,
      notify_at_start:
        parsedBeginAutomaticNotification &&
        parsedBeginAutomaticNotification.notifyOnDue
          ? 1
          : 0,
      notify_at_due:
        parsedDeliveryAutomaticNotification &&
        parsedDeliveryAutomaticNotification.notifyOnDue
          ? 1
          : 0,
      // fields: fieldsSelected[0].fields
    };

    if (
      Array.isArray(objectValues.signature_required) &&
      objectValues.signature_required[0] == "on"
    ) {
      objectValues.signature_required = 1;
    }

    if (
      Array.isArray(objectValues.checkin_required) &&
      objectValues.checkin_required[0] == "on"
    ) {
      objectValues.checkin_required = 1;
    }

    if (values.form_id) {
      objectValues.fields = selectedFieldsFromModal
        ? selectedFieldsFromModal
        : getFormFields(values.form_id);
    } else {
      objectValues.fields = null;
    }

    if (taskEditingData) {
      putTask(objectValues, setSubmitting, resetForm, taskEditingData.id);
    } else {
      postTask(objectValues, setSubmitting, resetForm);
    }
  };

  useEffect(() => {
    AxiosInstance.get(userStore.companyId + "/service").then((res) => {
      setServices(
        res.data.data.map((elem) => ({ value: elem.id, name: elem.name })),
      );
    });
    AxiosInstance.get(userStore.companyId + "/inventory").then((res) => {
      setInventory(
        res.data.data.map((elem) => ({ value: elem.id, name: elem.name })),
      );
    });
    AxiosInstance.get(userStore.companyId + "/users").then((res) => {
      setUsers(
        res.data.data.map((elem) => ({ value: elem.id, name: elem.name })),
      );
    });
    AxiosInstance.get(
      userStore.companyId + "/statuses/" + userStore.subprojectId,
    ).then((res) => {
      setSubprojectStatus(
        res.data.data.map((elem) => ({ value: elem.id, name: elem.name })),
      );
    });
    AxiosInstance.get(userStore.companyId + "/sector").then((res) => {
      setSector(
        res.data.data.map((elem) => ({ value: elem.id, name: elem.name })),
      );
    });
    // AxiosInstance.get("/task/form/").then((res) => {
    //   setFields(
    //     res.data.data.map((elem) => ({
    //       value: elem.id,
    //       name: elem.description,
    //       fields: elem.fields,
    //     })),
    //   );
    // });

    TaskRoutes.getPriorities().then((res) => {
      const priorities = res.data.data.map((priority) => ({
        ...priority,
        value: priority.id,
      }));
      setPriorities(priorities);
    });
  }, [userStore.companyId, userStore.subprojectId]);

  useEffect(async () => {
    const res = await formRoutes.listByCompanyId(userStore.companyId);
    const forms = res.data.data;

    setForms(
      forms.map(({ id, description, fields }) => {
        return {
          value: id,
          name: description,
          fields,
        };
      }),
    );
  }, [userStore.companyId]);

  const formValidation = (values) => {
    const errors = {};

    if (!values.start_date) {
      errors.start_date = "Campo obrigatório!";
    }

    if (!values.name) {
      errors.name = "Campo obrigatório!";
    }

    if (!values.status_id) {
      errors.status_id = "Campo obrigatório!";
    }

    if (values.priority_id !== 0 && !values.priority_id) {
      errors.priority_id = "Campo obrigatório!";
    }

    if (values.usesRecurrence && !values.recurrence) {
      errors.recurrence = "Selecione uma recorrência";
    }

    // if (!values.due_date) {
    //   errors.due_date = "Campo obrigatório!";
    // }

    return errors;
  };

  const onChange = ({ fileList: newFileList }) => {
    setArrayFiles(newFileList);
  };

  const removeAttachments = (file) => {
    setFilesToDelete((filesId) => [...filesId, file.id]);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    if (file.extension == "pdf") {
      const iframe = new Blob([file.url], { type: "application/pdf" });
      iframe.src = src;
      const iframeWindow = window.open(src);

      iframeWindow.URL.revokeObjectURL(iframe.outerHTML);
    } else {
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);

      imgWindow.document.write(image.outerHTML);
    }
  };

  const getInitialValues = () => {
    if (taskEditingData) {
      if (taskEditingData.recorrence_end) {
        taskEditingData.usesRecurrence = true;
        taskEditingData.recurrence = taskEditingData.recorrence_end_type;
      } else {
        taskEditingData.usesRecurrence = false;
      }

      if (taskEditingData.checkin_required == 1) {
        taskEditingData.checkin_required = true;
      } else {
        taskEditingData.checkin_required = false;
      }
      if (taskEditingData.signature_required == 1) {
        taskEditingData.signature_required = true;
      } else {
        taskEditingData.signature_required = false;
      }
      //delete taskEditingData.recorrence_end;
      //delete taskEditingData.recorrence_end_type;

      const observers_id = [];
      let beginAutomaticNotification = "";
      let deliveryAutomaticNotification = "";

      if (taskEditingData.observers) {
        for (const observer of taskEditingData.observers) {
          observers_id.push(observer.id);
        }
      }

      if (taskEditingData.notify_before_start != null)
        beginAutomaticNotification = JSON.stringify({
          daysBefore: taskEditingData.notify_before_start,
          notifyOnDue: taskEditingData.notify_at_start ? true : false,
        });

      if (taskEditingData.notify_before_due != null)
        deliveryAutomaticNotification = JSON.stringify({
          daysBefore: taskEditingData.notify_before_due,
          notifyOnDue: taskEditingData.notify_at_due ? true : false,
        });

      return {
        ...taskEditingData,
        inventory_id: taskEditingData.inventory?.id,
        sector_id: taskEditingData.sector?.id,
        service_id: taskEditingData.service?.id,
        observers_id,
        beginAutomaticNotification,
        deliveryAutomaticNotification,
      };
    }

    return initialTask;
  };

  function getFormDescription(formId) {
    return (
      forms.find((form) => Number(form.value) === Number(formId))?.name || ""
    );
  }

  function getFormFields(formId) {
    return (
      forms.find((form) => Number(form.value) === Number(formId))?.fields || []
    );
  }

  const handleSelectedFieldsChange = (newSelectedFields) => {
    setSelectedFieldsFromModal(newSelectedFields);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={getInitialValues()}
        innerRef={formikRef}
        onSubmit={handleFormSubmit}
        validate={formValidation}
      >
        {({
          values,
          handleChange,
          isSubmitting,
          errors,
          touched,
          setFieldValue,
        }) => {
          return (
            <Form className="w-100">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-8">
                  <FormikInput
                    placeholder="Título"
                    name="name"
                    labelBackground="white"
                    disabled={titleDisabled}
                    style={{
                      cursor: titleDisabled ? "not-allowed" : "default",
                    }}
                  />
                </div>

                <div className="col-sm-4 col-md-2 col-lg-1">
                  <FormikSwitchCheckbox
                    id="signature_required"
                    name="signature_required"
                    label="Assinatura"
                    checked={values.signature_required}
                  />
                </div>

                <div className="col-sm-4 col-md-2 col-lg-2">
                  <FormikSwitchCheckbox
                    id="checkin_required"
                    name="checkin_required"
                    label="Check-In/Out"
                    checked={values.checkin_required}
                  />
                </div>

                <div className="col-sm-4 col-md-2 col-lg-1">
                  <FormikSwitchCheckbox
                    id="usesRecurrence"
                    name="usesRecurrence"
                    label="Recorrência"
                    checked={values.usesRecurrence}
                  />
                </div>
                {/*<SwitchCheckbox checked={values.checkin_required}*/}
                {/*                id="checkin_required"*/}
                {/*                label="Recorrência"*/}
                {/*                onChange={handleChange}*/}
                {/*/>*/}
              </div>
              <div className="mt-4">
                <FormikInput
                  placeholder="Descrição"
                  name="description"
                  inputType="textArea"
                  labelBackground="white"
                />
              </div>

              <div
                className="mt-4"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "12px",
                }}
              >
                <Select
                  placeholder="Serviço"
                  id="service_id"
                  errors={errors.service_id}
                  touched={touched.service_id}
                  value={values.service_id}
                  handleChange={handleChange}
                  inputBackground="transparent"
                  inputPlaceholder=""
                  options={services}
                  withNull={true}
                />
                <div>
                  <Select
                    placeholder="Prioridade"
                    id="priority_id"
                    errors={errors.priority_id}
                    touched={touched.priority_id}
                    value={values.priority_id}
                    handleChange={handleChange}
                    inputBackground="transparent"
                    inputPlaceholder=""
                    options={priorities}
                    withNull
                  />
                </div>
                <Select
                  placeholder="Inventário"
                  id="inventory_id"
                  errors={errors.inventory_id}
                  touched={touched.inventory_id}
                  value={values.inventory_id}
                  handleChange={handleChange}
                  inputBackground="transparent"
                  inputPlaceholder=""
                  options={inventory}
                  withNull={true}
                />
              </div>

              <div className="row mt-4">
                <div className="col-sm-12 col-md-6">
                  <Select
                    placeholder="Setor"
                    id="sector_id"
                    errors={errors.sector_id}
                    touched={touched.sector_id}
                    value={values.sector_id}
                    handleChange={handleChange}
                    inputBackground="transparent"
                    inputPlaceholder=""
                    options={sector}
                    withNull={true}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <Select
                    placeholder="Responsável"
                    id="responsible_id"
                    errors={errors.responsible_id}
                    touched={touched.responsible_id}
                    value={values.responsible_id}
                    handleChange={handleChange}
                    inputBackground="transparent"
                    inputPlaceholder=""
                    options={users}
                    withNull
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-2">
                  <InputDatepicker
                    name="start_date"
                    errors={errors.start_date}
                    touched={touched.start_date}
                    label="Início"
                    placeholder="Selecionar Data"
                    labelBackground="white"
                    disabled={
                      !checkPermission("alterar-datas") && taskEditingData
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <AutomaticNotificationSelect
                    placeholder="Notificar o início em"
                    name="beginAutomaticNotification"
                    id="beginAutomaticNotification"
                    errors={errors.beginAutomaticNotification}
                    touched={touched.beginAutomaticNotification}
                    value={values.beginAutomaticNotification}
                    inputBackground="transparent"
                    inputPlaceholder=""
                    options={automaticNotificationOptions}
                    disabled={!values.start_date}
                    withNull
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-2">
                  <InputDatepicker
                    name="due_date"
                    errors={errors.due_date}
                    touched={touched.due_date}
                    label="Entrega"
                    placeholder="Selecionar Data"
                    labelBackground="white"
                    disabled={
                      !checkPermission("alterar-datas") && taskEditingData
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <AutomaticNotificationSelect
                    placeholder="Notificar a entrega em"
                    name="deliveryAutomaticNotification"
                    id="deliveryAutomaticNotification"
                    errors={errors.deliveryAutomaticNotification}
                    touched={touched.deliveryAutomaticNotification}
                    value={values.deliveryAutomaticNotification}
                    inputBackground="transparent"
                    inputPlaceholder=""
                    options={automaticNotificationOptions}
                    disabled={!values.due_date}
                    withNull
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-sm-12 col-lg-6 col-xl-4 mb-4">
                  <FormikAntdSelect
                    mode="tags"
                    allowClear
                    placeholder="Observadores"
                    name="observers_id"
                    id="observers_id"
                    errors={errors.observers_id}
                    touched={touched.observers_id}
                    value={values.observers_id}
                    inputBackground="transparent"
                    inputPlaceholder=""
                    options={users}
                    maxTagCount="responsive"
                  />
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                  <Select
                    placeholder="Status"
                    id="status_id"
                    errors={errors.status_id}
                    touched={touched.status_id}
                    value={values.status_id}
                    handleChange={handleChange}
                    inputBackground="transparent"
                    inputPlaceholder=""
                    options={subprojectStatus}
                    withNull={true}
                  />
                </div>

                <div className="d-flex justify-content-between col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                  <div style={{ width: "80%" }}>
                    <Select
                      placeholder="Formulário"
                      id="form_id"
                      value={values.form_id}
                      handleChange={handleChange}
                      inputBackground="transparent"
                      inputPlaceholder=""
                      options={forms}
                      withNull
                    />
                  </div>
                  <div style={{ width: "15%" }}>
                    <Button
                      className="d-flex justify-content-center align-items-center"
                      type="primary"
                      icon={<FormOutlined />}
                      style={{ height: "100%", width: "100%" }}
                      disabled={!values.form_id}
                      onClick={() => setFormFieldsModalIsVisible(true)}
                    />
                  </div>
                </div>

                {values.usesRecurrence && (
                  <>
                    <div className="col-sm-12 col-md-6 col-xl-2 mb-4">
                      <Select
                        placeholder="Recorrência"
                        id="recurrence"
                        errors={errors.recurrence}
                        touched={touched.recurrence}
                        value={values.recurrence}
                        handleChange={handleChange}
                        inputBackground="transparent"
                        inputPlaceholder=""
                        options={recurrences}
                        withNull
                      />
                    </div>

                    <div className="col-sm-12 col-md-6 col-xl-2 mb-4">
                      <FormikInput
                        name="repetitions"
                        placeholder="N° de repetições"
                        icon={{
                          name: "help",
                          tooltip:
                            'Nas repetições contínuas não há limite de repetições, sempre que uma tarefa com repetição contínua é concluída, outra tarefa com as mesmas características é criada com o status "A FAZER" com a data de início conforme a frequência escolhida',
                          style: { cursor: "default" },
                        }}
                        labelBackground="white"
                        inputPlaceholder="Contínua"
                        onChange={(e) => {
                          let { value } = e.target;

                          value = value.replace(/\D+/g, "");

                          if (value === "0" || !value)
                            return setFieldValue("repetitions", "");

                          return setFieldValue("repetitions", value);
                        }}
                      />
                    </div>
                  </>
                )}
                {/* <Select
                  placeholder="Checklist"
                  id="fields"
                  // errors={errors.fields}
                  // touched={touched.fields}
                  // value={values.fields}
                  // handleChange={handleChange}
                  inputBackground="transparent"
                  inputPlaceholder=""
                  options={fields}
                  withNull={true}
                /> */}
              </div>
              <div className="mt-2">
                <p>
                  Adicionar mídia
                  <small> (Máximo 5 arquivos)</small>
                </p>
                <div className="d-flex">
                  <Upload
                    accept="*"
                    listType="picture-card"
                    fileList={arrayFiles}
                    onChange={onChange}
                    onPreview={onPreview}
                    onRemove={taskEditingData && removeAttachments}
                    beforeUpload={() => false}
                    maxCount={5}
                    openFileDialogOnClick={arrayFiles.length < 5}
                  >
                    <div className="upload-file">
                      {arrayFiles.length < 5 ? (
                        <>
                          <UploadOutlined className="upload-icon" />
                          <p>Clique ou arraste e solte para adicionar</p>
                        </>
                      ) : (
                        <>
                          <ExclamationCircleOutlined className="upload-icon" />
                          <p>
                            Limite de upload de arquivos atingido! (Máximo 5)
                          </p>
                        </>
                      )}
                    </div>
                  </Upload>
                </div>
              </div>
              <div className="div-button-submit mt-5">
                <button
                  className="btn-default"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <BeatLoader
                      color="#000000"
                      loading={isSubmitting}
                      size={10}
                    />
                  ) : (
                    "Salvar"
                  )}
                </button>
              </div>

              {forms && forms.length > 0 && (
                <FormFieldsModal
                  isVisible={formFieldsModalIsVisible}
                  formDescription={getFormDescription(values.form_id)}
                  fields={getFormFields(values.form_id)}
                  onCancel={() => setFormFieldsModalIsVisible(false)}
                  onSelectedFieldsChange={handleSelectedFieldsChange}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ModalCreateTask;
